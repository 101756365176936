@font-face {
  font-family: 'regular_regular';
  font-display: swap;
  src: url('./static/fonts/Regular/Regular-Regular.otf')  format("opentype");
}
@font-face {
  font-family: 'regular_medium';
  font-display: swap;
  src: url('./static/fonts/Regular/Regular-Medium.otf')  format("opentype");
}
@font-face {
  font-family: 'regular_semibold';
  font-display: swap;
  src: url('./static/fonts/Regular/Regular-Semibold.otf')  format("opentype");
}
html {
  box-sizing: border-box;
  overflow: hidden;
}
*, *:before, *:after {
  box-sizing: inherit;
}
h1, h2, h3, h4, h5, h6 {
  font-family: regular_semibold;
  margin: 0;
}
div {
  font-family: regular_medium;
}
p {
  margin: 0;
}
.flex {
  display: flex;
}

.container {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
}

.first-card {
  margin: 0 0px !important;
  box-shadow: 0 1px 3px 1px #ccc !important;
}
.card {
  margin: 20px 4px;
  background-color: #fff;
  box-shadow: 0 0 3px 1px #ccc;
}
.App {
  background-color: #f1f3f6;
  min-height: 100vh;
  font-family: 'regular_medium', sans-serif !important;
}

.show-yourself {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
}

.fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}
.fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}
.ant-popover-title {
  font-size: 16px;
}
.ant-popover-inner-content {
  padding: 0;
}
.datasheet-table-modal, .large-table-modal, .yoy-table-modal{
  width: 95% !important;
  top: 25px !important;
}
.customize-report-modal{
  width: 55% !important;
  top: 25px !important;
} 
.report-chat-modal {
  width: 90% !important;
  max-width: 576px !important;
  top: 40px !important;
}

.ant-modal.modal_downloadpopup {
  width: 90% !important;
  top: 30px;
}

.modal_downloadpopup button {
  top: -10px !important;
  right: -10px !important;
}

.customize-report-modal .ant-modal-content, .report-chat-modal .ant-modal-content {
  overflow: auto;
  max-height: 90vh;
}
.customize-report-modal .component-1 {
  display: none;
}
.customize-report-modal table {
  display: none;
}
.datasheet-table-modal .ant-modal-body {
  position: relative;
}
.datasheet-table-modal .ant-modal-body .download-datasheet-table {
  position: absolute;
  top: 10px;
  right: 31px;
}
.datasheet-table-modal .ant-modal-body .ant-table-wrapper {
  margin-top: 20px;
}
.datasheet-table-modal .ant-table-thead th {
  font-family: regular_semibold;
}
.datasheet-table-modal .ant-table-tbody td, .ant-radio-wrapper, .ant-table,.ant-select-dropdown, .ant-tabs, .ant-select, .ant-checkbox-wrapper, .ant-popover, .ant-tooltip-content {
  font-family: regular_medium;
}
.datasheet-table-modal .ant-table-thead th, .datasheet-table-modal .ant-table-tbody td {
  padding: 7px;
  word-break: break-word;
  text-align: center;
  font-size: 14px;
  min-width: 100px !important;
}
.report-chat-modal .chat-message-list {
  flex-direction: column;
} 
.report-chat-modal .chat-message-list .client-msg, .report-chat-modal .chat-message-list .support-msg {
  flex-direction: column;
  margin: 5px 0;
}
.report-chat-modal .chat-message-list .client-msg p, .report-chat-modal .chat-message-list .support-msg p {
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 400px;
}
.report-chat-modal .chat-message-list .client-msg p {
  background-color: #1890ff21;
  border-radius: 25px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .client-msg p:first-child {
  background-color: #1890ff21;
  border-radius: 25px 25px 2px 25px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .client-msg p:last-child {
  background-color: #1890ff21;
  border-radius: 25px 2px 25px 25px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .support-msg p {
  background-color: #43b1a921;
  border-radius: 25px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .support-msg p:first-child {
  background-color: #43b1a921;
  border-radius: 25px 25px 25px 2px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .support-msg p:last-child {
  background-color: #43b1a921;
  border-radius: 2px 25px 25px 25px;
  margin: 3px 0;
}
.report-chat-modal .chat-message-list .client-msg-body .initials, .report-chat-modal .chat-message-list .support-msg-body .initials {
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 50%;
  padding-top: 8px;
}
.report-chat-modal .chat-message-list .client-msg-body .initials {
  background-color: #1890ff;
  margin: 10px 0px 0px 5px;
}
.report-chat-modal .chat-message-list .support-msg-body .initials {
  background-color: #43b1a9;
  margin: 10px 5px 0px 0px;
}
.report-chat-modal .chat-message-list .client-msg-body {
  align-self: flex-end;
  flex-direction: row-reverse;
}
.report-chat-modal .chat-message-list .support-msg-body {
  align-self: flex-start;
}
.report-chat-modal .chat-message-list .client-msg {
  align-items: flex-end;
}
.report-chat-modal .chat-message-list .support-msg {
  align-items: flex-start;
}
.report-chat-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 15px -2px #ccc;
}
.report-chat-modal .ant-modal-header {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 15px -2px #ccc;
  padding: 16px 8px 16px 24px;
}
.report-chat-modal .ant-modal-header .ant-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-chat-modal .send-icon {
  margin-left: 10px;
  cursor: pointer;
}
.report-chat-modal .close-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.report-chat-modal .chat-message-form {
  width: 100%;
  align-items: center;
}
.report-chat-modal .chat-message-form input {
  flex-grow: 1;
  margin-right: 5px;
  outline: none;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 0 2px transparent;
  transition: all 0.2s linear;
}
.report-chat-modal .chat-message-form input:hover {
  border-color: #1890ff;
}
.report-chat-modal .chat-message-form input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px #1890ff33;
}
.report-chat-modal .chat-message-form button {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 24px;
  border: none;
  background: transparent;
  outline: none;
}

.goto-profile {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}

.goto-profile:hover {
  padding-left: 6px;
  background-color: #F5FAF9;
  border-left: 4px solid #2c8ebf
}

.profile-link {
  color: #333;
}
.global-analysis-modal, .multidata-analysis-modal {
  width: 80% !important;
}

.global-analysis-modal .ant-modal-body, .multidata-analysis-modal .ant-modal-body {
  padding: 24px 10%;
}
.large-table-modal .ant-modal, .customize-report-modal .ant-modal {
  top: 50px;
}
.large-table-modal .ant-modal-body, .yoy-table-modal .ant-modal-body, .customize-report-modal {
  padding: 20px;
}
.customize-report-modal h2 {
  font-size: 20px;
}
.global-analysis-modal p, .multidata-analysis-modal p {
  margin-bottom: 30px;
}
.multidata-analysis-modal .years-radio-wrapper {
  width: 17%;
  justify-content: center;
  align-items: center;
}
.multidata-analysis-modal .years-radio-wrapper .ant-radio-wrapper {
  padding: 10px 0;
}
input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: searchfield;
} 
input[type="search"]::-webkit-search-cancel-button {
 -webkit-appearance: searchfield-cancel-button;
}

.download-report-list .file-name {
  width: 50%;
}

.download-report-list .file-type, .download-report-list .file-action {
  width: 25%;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #90c4ea9c;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #108ee99c;
}
::-webkit-scrollbar-thumb:active {
  background-color: #108ee99c;
}
.hide-collapsed-filters {
  display: none !important;
}

.rnd-links-modal, .rnd-news-modal {
  width: 80% !important;
}

.rnd-links-modal .common-data, .rnd-news-modal .common-data {
  flex-wrap: wrap;
  border-bottom: 2px solid #333;
  margin: 10px 0;
}

.rnd-links-modal .common-data .data-container, .rnd-news-modal .common-data .data-container {
  width: calc(25% - 20px);
  margin: 10px;
}

.rnd-links-modal .common-data .data-container .links-data-label, .rnd-news-modal .common-data .data-container .links-data-label {
  font-family: regular_semibold;
  margin-right: 10px;
}

.rnd-links-modal .common-data .data-container .links-data-label, .rnd-links-modal .common-data .data-container .links-data-info, .rnd-news-modal .common-data .data-container .links-data-label, .rnd-news-modal .common-data .data-container .links-data-info {
  font-size: 14px;
}

.rnd-links-modal .links-data .links-section h3, .rnd-news-modal .news-data .links-section h3 {
  margin: 20px 0 0 0;
}

@media (max-width: 1220px) and (orientation: landscape) {
  .hide-collapsed-filters {
    display: block !important;
  }
}
@media (max-width: 1024px) and (orientation: portrait) {
  .hide-collapsed-filters {
    display: block !important;
  }
}
@media (min-width: 1220px) {
  .App .container {
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .App .container {
    max-width: 1600px;
  }
}